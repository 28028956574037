//color-variable
$color-primary: #250c6d;
$color-primary-fade: #68649c;
$color-black: #212121;
$color-white: #fff;
$color-main-button: #e25e53;
$primary: #C74339;
$dark: #222222;
$black: #000000;

//font-variable
$main-font-weight: 600;
