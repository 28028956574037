@import "../Styles/variables.scss";

//body
body {
  background-color: #ffffff;
  .form-control {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  button {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.pendingColor {
  color: #ffb075;
}

.rejectedColor {
  color: #ff0000;
}

.approvedColor {
  color: #00c12a;
}

.colorTheme {
  color: #e25e52;
}

.colorDark {
  color: #000;
}

//common font weight style------------
.f600 {
  font-weight: 600;
}

//scroll
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.google-custom {
  border-radius: 100px;
  background-color: transparent !important;
  border: none !important;
  width: 40px !important;
  height: 40px;
  padding: 5px !important;
  img {
    width: 100%;
  }
}

//header
.mobile-header {
  display: none;
  background-color: rgba(210, 102, 88, 0.2);
  padding: 40px 20px 20px 20px;
  .header-desc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .menu-icon {
      width: 26px;
      object-fit: contain;
      height: auto;
    }
    .logo {
      width: 160px;
      object-fit: contain;
      height: auto;
    }
    .cart-count {
      position: relative;
      p {
        margin: 0;
        width: 25px;
        height: 25px;
        border-radius: 100px;
        background-color: #e25e52;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 14px;
        position: absolute;
        top: -12px;
        left: -12px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    display: block;
  }
  .header-search-mobile {
    position: relative;
    margin-top: 20px;
    margin-bottom: 0;
    input {
      padding: 0px 10px 0 44px;
      border: none;
      border-radius: 0;
      height: 46px;
    }
    i {
      position: absolute;
      top: 15px;
      left: 12px;
      font-size: 20px;
      color: #9c9c9c;
    }
  }
}
.header {
  @media screen and (max-width: 767px) {
    display: none;
  }
  .header-top {
    padding: 15px 0px;
    .header-logo {
      img,
      svg {
        width: 100%;
      }
    }

    .header-search {
      position: relative;
      .header-top-search {
        display: flex;
        align-items: center;
        position: relative;
        button {
          background-color: transparent;
          color: #e25e53;
          border: none;
          padding: 0px;
          height: 25px;
          width: 25px;
          padding-right: 10px;
        }
        .form-control {
          border-radius: 0px;
          border-color: #00000029;
          border: 0px;
          height: 30px;
          border-bottom: 1px solid #777777;
          padding-left: 0px;
          font-size: 14px;
          font-weight: 500;
          background-color: transparent;
        }
      }
    }

    .header-top-search {
      display: flex;
      align-items: center;
      position: relative;
      button {
        background-color: transparent;
        color: #e25e53;
        border: none;
        padding: 0px;
        height: 25px;
        width: 25px;
        padding-right: 10px;
      }
      .form-control {
        border-radius: 0px;
        border-color: #00000029;
        border: 0px;
        height: 30px;
        border-bottom: 1px solid #777777;
        padding-left: 0px;
        font-size: 14px;
        font-weight: 500;
        background-color: transparent;
      }
    }
    .header-top-menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      ul {
        margin: 0px;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        li {
          list-style: none;
          margin: 0 4px 0 12px;
          @media (min-width: 768px) and (max-width: 991px) {
            margin: 0 4px 0 8px;
          }
          &.dropdown {
            &:hover {
              .dropdown-menu {
                display: block;
                border-radius: 0;
                top: 23px;
              }
            }
            .dropdown-toggle {
              outline: none;
              box-shadow: none;
              color: #000;
              font-weight: 500;
              font-size: 13px;
              text-transform: uppercase;
              i {
                color: #e25e53;
                margin-right: 4px;
                font-size: 22px;
              }
            }
            .dropdown-menu {
              right: 0;
              left: auto;
            }
          }
          a {
            color: #000;
            font-weight: 500;
            font-size: 13px;
          }
          .header-cart {
            position: relative;
            color: #000;
            font-weight: 500;
            font-size: 13px;
            margin: 0;
            i {
              color: #e25e53;
              margin-right: 4px;
              font-size: 22px;
            }
            .add-digit {
              position: absolute;
              top: -15px;
              left: 15px;
              width: 20px;
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #000;
              color: #fff;
              border-radius: 100%;
              font-size: 8px;
            }
          }
        }
      }
    }
  }
  .header-mid {
    margin-bottom: 15px;
    img {
      width: 100%;
    }
  }
  .header-bottom {
    margin-bottom: 10px;
    .navbar {
      padding: 0px;
      .navbar-collapse {
        .navbar-nav {
          justify-content: space-between;
          flex-wrap: wrap;
          width: 100%;
          .nav-item {
            .nav-link {
              text-transform: uppercase;
              font-size: 14px;
              font-weight: 500;
              color: #000000;
              i {
                color: #e25e53;
                margin-right: 3px;
              }
              &:hover {
                color: #979797;
              }
            }
          }
        }
      }
    }
  }
}

//order-track
.admin-card {
  width: 100%;
}

//buttons
.disable-btn {
  background-color: grey !important;
  opacity: 0.5;
  border: 1px solid grey !important;
  color: #fff;
  &:hover {
    border: 1px solid !important;
    color: #fff !important;
  }
}

.opacity-btn {
  opacity: 0.6;
}

.disablebtn-right {
  opacity: 0.5;
  background-color: grey !important;
  color: #fff !important;
  cursor: default !important;
}

.orange-outline-btn {
  border: 1px solid #e25e52;
  color: #e25e52;
  background-color: transparent;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 6px 20px;
  min-width: auto;
  &:hover,
  &:focus {
    background-color: #e25e52;
    color: #fff;
  }
}

.orange-outline-btn-font {
  border: 1px solid #e25e52;
  color: #e25e52;
  background-color: transparent;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 6px 20px;
  min-width: auto;
}

.orange-btn {
  border: 1px solid #e25e52;
  color: #fff;
  background-color: #e25e52;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 6px 20px;
  min-width: auto;
  &:hover {
    color: #fff;
  }
}

.grey-btn {
  border: 1px solid #6c757d;
  color: #fff;
  background-color: #6c757d;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 6px 20px;
  min-width: auto;
  &:hover,
  &:focus {
    background-color: #6c757d;
    color: #fff;
  }
}

//dealCardBox
.dealCard {
  padding: 40px 0px;
  .dealCardBox {
    position: relative;
    padding: 40px 20px;
    border-radius: 15px 0px 15px 0px;
    box-shadow: 2px 4px 12px 0px #8888882e;
    margin-bottom: 30px;
    &.dealOrangeBox {
      background-color: #e25e53;
    }
    &.dealWhiteBox {
      background-color: #ffffff;
      .dealCardBoxDetail {
        h2 {
          color: #000;
        }
        p {
          color: #000;
        }
        a {
          color: #000;
        }
      }
    }
    .dealCardBoxDetail {
      width: 80%;
      h2 {
        color: #fff;
        text-transform: uppercase;
      }
      p {
        color: #fff;
        text-transform: uppercase;
      }
      a {
        color: #fff;
        text-transform: uppercase;
        &:hover {
        }
      }
    }
    .dealCardBoxImage {
      position: absolute;
      &.dealCardBox-orangeImage {
        top: 15px;
        right: 15px;
      }
      &.dealCardBox-whiteImage {
        bottom: 15px;
        right: 15px;
      }
      img {
        max-width: 100px;
        width: 100%;
      }
    }
  }
}

.home-product-section {
  @media screen and (max-width: 767px) {
    .col-6 {
      padding: 0 4px;
    }
    select {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .home-heading-mobile {
    display: none;
  }
}

//home-section-heading
.home-section-heading {
  h3 {
    margin-bottom: 0;
  }
  .hover-breadcrumb {
    font-weight: 400;
    cursor: pointer;
    text-transform: uppercase;
    span {
      font-weight: 600;
    }
    &:hover {
      font-weight: 600;
      border-bottom: 1px solid;
      span {
        color: #e25e52;
      }
    }
  }
  ul {
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px) {
      justify-content: space-between;
    }
    li {
      list-style: none;
      margin-right: 10px;
      @media screen and (max-width: 767px) {
        &:nth-child(2) {
          display: none;
        }
        &:nth-child(3) {
          a {
            font-size: 16px;
            color: #e25e52;
            font-weight: 600;
          }
        }
      }
      a {
        color: #000;
        font-size: 14px;
        text-transform: uppercase;
      }
      h2 {
        text-transform: uppercase;
        margin-bottom: 0px;
        font-size: 20px;
        font-weight: 700;
        span {
          color: $color-main-button;
        }
      }
      p {
        color: #000;
        font-size: 14px;
        text-transform: uppercase;
      }
    }
  }
}

.button-category-shop {
  background-color: #fff;
  color: $color-main-button;
  padding: 15px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  border: 1px solid $color-main-button;
  cursor: pointer;
  &:hover {
    background-color: $color-main-button;
    color: #fff;
    border: 1px solid $color-main-button;
    a {
      color: #fff;
    }
  }
  a {
    color: $color-main-button;
  }
  span {
    margin-right: 10px;
  }
}

//home-product
.home-product {
  position: relative;
  position: relative;
  margin-bottom: 30px;
  img {
    width: 100%;
  }
  a {
    position: absolute;
    top: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.4);
    .home-product-detail {
      i {
        color: #e25e53;
        font-size: 18px;
        margin-bottom: 5px;
      }
      h3 {
        color: #fff;
        text-transform: uppercase;
        font-size: 20px;
        margin-bottom: 3px;
      }
      p {
        color: #fff;
        text-transform: uppercase;
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  &:hover {
    a {
      opacity: 1;
    }
  }
}

//main-footer
.main-footer {
  background-color: $black;
  .footer-top {
    padding: 50px 0px 20px;
    border-bottom: 1px solid rgba($color-white, 0.5);
    .newsletter-heading {
      p {
        color: $color-white;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
      }
    }

    //footer-newsletter
    .footer-newsletter {
      .foote-newsletter-detail {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 10px;
        .newsletter-input {
          display: flex;
          align-items: center;
          border: 1px solid rgba($color-white, 0.5);
          width: 320px;
          gap: 10px;
          padding: 8px 12px;
          border-radius: 8px;
          svg {
            width: 20px;
            height: 20px;
            min-width: 20px;
          }
          .form-control {
            border-radius: 0px;
            border-color: #00000029;
            border: 0px;
            height: 30px;
            padding-left: 0px;
            font-size: 14px;
            font-weight: normal;
            color: $color-white;
            //text-transform: uppercase;
            background-color: transparent;
            ::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: #989898;
              opacity: 1; /* Firefox */
            }

            :-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: #989898;
            }

            ::-ms-input-placeholder {
              /* Microsoft Edge */
              color: #989898;
            }
          }
        }
      }
      .news-msg {
        .alert {
          width: 280px;
          margin: 0 auto;
          margin-top: 20px;
        }
      }

      @media only screen and (max-width: 767px) {
        .foote-newsletter-detail {
          flex-flow: column;
          align-items: flex-start;
          .newsletter-input {
            width: 100%;
          }
        }
      }
    }
  }

  //footer-mid
  .footer-mid {
    padding: 40px 0px;
    border-bottom: 1px solid rgba($color-white, 0.5);
    //footer-about
    .footer-about {
      .footer-logo {
        margin-bottom: 20px;
        img {
          width: 220px;
          height: auto;
        }
      }
      .footer-social-links {
        ul {
          margin: 0px;
          padding: 0px;
          display: flex;
          align-items: center;
          gap: 10px;
          li {
            list-style: none;
            a {
              border: 1px solid rgba($color-white, 0.5);
              width: 40px;
              height: 40px;
              min-width: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 100%;
              svg {
                width: 20px;
                height: 20px;
                min-width: 20px;
              }
              &:hover {
                border-color: $primary;
                background-color: rgba($primary, 1);
              }
            }
          }
        }
      }
    }
    //footer-link
    .footer-link {
      h3 {
        color: $color-white;
        font-size: 16px;
        font-weight: 400;
        text-transform: capitalize;
        margin-bottom: 20px;
        opacity: 0.5;
      }
      .footer-nav {
        ul {
          margin: 0px;
          padding: 0px;
          li {
            list-style: none;
            margin-bottom: 10px;
            a {
              color: $color-white;
              font-size: 14px;
              font-weight: normal;
              text-transform: capitalize;
              &:hover {
                color: #e25e52;
              }
            }
          }
        }
      }
    }
  }

  //footer-bottom
  .footer-bottom {
    padding: 15px 0px;
    position: relative;
    .rights-text {
      p {
        margin-bottom: 0px;
        font-size: 12px;
        color: rgba($color-white, 1);
        span {
          color: $primary;
        }
      }
    }

    .footer-bottom-links {
      display: flex;
      align-items: center;
      gap: 15px;
      justify-content: flex-end;
      a {
        color: rgba($color-white, 1);
        font-size: 12px;
        font-weight: 500;
        &:hover {
          color: #e25e52;
        }
      }
    }

    .scrollToTopBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 40px;
      height: 35px;
      bottom: 6px;
      right: 30px;
      font-size: 18px;
      border: 2px solid #e25e53;
      outline: none;
      background-color: white;
      color: #e25e53;
      cursor: pointer;
      padding: 15px;
      border-radius: 4px;
      &:hover {
        background-color: #e25e53;
        color: white;
      }
    }
    @media only screen and (max-width: 767px) {
      .rights-text {
        text-align: center;
        margin-bottom: 10px;
      }
      .footer-bottom-links {
        justify-content: center;
      }
    }
  }
}

//signup
.signup {
  max-width: 500px;
  margin: 40px auto;
  border: 0.5px solid #989898;
  padding: 40px;
  background-color: #fff;
  .signup-head {
    text-align: center;
    margin-bottom: 40px;
    h2 {
      color: #000000;
      font-size: 20px;
      margin-bottom: 5px;
      text-transform: uppercase;
      font-weight: 500;
    }
    p {
      color: #000000;
      font-size: 12px;
      margin-bottom: 0px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
  .signup-mid {
    h3 {
      color: #000000;
      font-size: 14px;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-weight: 500;
    }
    .form-group {
      position: relative;
      .form-control {
        border: 0px;
        //text-transform: uppercase;
        font-size: 12px;
        border-bottom: 1px solid #d1d1d1;
        border-radius: 0px;
        padding-left: 0px;
        padding-right: 0px;
        font-weight: 500;
        color: #000;
      }
      span {
        color: red;
        font-size: 12px;
      }
      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #989898;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #989898;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #989898;
      }
    }
  }
  .signup-bottom {
    .signup-submit {
      margin: 25px 0px 15px;
      button {
        border: 1px solid #e25e52;
        color: #e25e52;
        background-color: transparent;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 10px 20px;
        width: 100%;
        &:hover {
          background-color: #e25e52;
          color: #fff;
        }
      }
    }
    .icon-same-line {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
    .already-account-link {
      text-align: center;
      margin-top: 25px;
      p {
        color: #000000;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 0px;
        text-transform: uppercase;
        a {
          color: #e25e52;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    .forgot-password {
      text-align: center;
      a {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 0px;
        text-transform: uppercase;
        color: #e25e52;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .signup-trums-check {
    display: flex;
    .sign-check {
      margin-right: 15px;
      .form-field-error {
        color: red;
        font-size: 12px;
      }
    }
  }
}

//custom-checkbox
.custom-check {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
  color: #989898;
  font-weight: 500;
  text-transform: uppercase;
}

.custom-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-check .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 14px;
  width: 14px;
  border: 1px solid #777777;
}

.custom-check input:checked ~ .checkmark {
  background-color: #e25e52;
  border-color: #e25e52;
}

.custom-check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-check input:checked ~ .checkmark:after {
  display: block;
}

.custom-check .checkmark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

//product-list
.select-product-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-top: 10px;
  span {
    i {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    select {
      display: none;
    }
    span {
      i {
        color: #7f7f7f;
        font-size: 20px;
        display: block;
      }
    }
  }
  .product-list-head {
    padding-bottom: 10px;
    @media screen and (max-width: 767px) {
      select {
        display: none;
      }
      padding-bottom: 0;
    }
    &.product-list-border-bottom {
      width: 100%;
      border-bottom: 1px solid #d0d0d0;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    ul {
      margin: 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      li {
        list-style: none;
        margin-right: 6px;
        p,
        a {
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 700;
          color: #000000;
          margin-bottom: 0px;
        }
        i {
          color: #e25d56;
        }
      }
      .product-list-slug-name {
        word-break: break-word;
      }
    }
  }
}

// common css
.white-space-break {
  white-space: initial !important;
  width: 200px;
}

//home-carousel
.home-slider {
  margin-bottom: 30px;
  margin-top: 30px;
  @media screen and (max-width: 767px) {
    margin: 0;
    .container {
      padding: 0;
    }
    .banner-mobile-owl {
      display: block !important;
    }
  }
  .carousel {
    .carousel-indicators {
      @media screen and (max-width: 767px) {
        bottom: -42px;
        li {
          border: 1px solid #707070 !important;
          i {
            display: none;
          }
          &.active {
            background-color: #e25e52;
            border: 1px solid #e25e52 !important;
          }
        }
      }
      li {
        border-radius: 100%;
        border: 1px solid transparent;
        background-color: transparent;
        width: 17px;
        height: 17px;
        opacity: 1;
        text-indent: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          color: #fff;
          font-size: 6px;
          padding-left: 1px;
        }
        &.active {
          border-color: #fff;
        }
      }
    }
  }
  .carousel-inner {
    .carousel-item {
      img {
        width: 100%;
        @media screen and (max-width: 767px) {
          min-height: 350px !important;
          object-fit: cover;
          object-position: center;
        }
      }
      .carousel-caption {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        span {
          font-size: 22px;
          font-weight: bold;
          color: #e25e52;
        }
        h3 {
          font-size: 100px;
          text-transform: uppercase;
          line-height: normal;
        }
        p {
          font-size: 20px;
          font-weight: 600;
          color: #ffffff;
          max-width: 600px;
          margin-bottom: 20px;
        }
        .banner-shopbtn {
          button {
            border: 1px solid #e25e52;
            color: #fff;
            background-color: #e25e52;
            font-size: 14px;
            font-weight: normal;
            text-transform: uppercase;
            padding: 10px 30px;
          }
        }
        .banner-shopbtn {
          @media screen and (max-width: 767px) {
            position: absolute;
            bottom: 18px;
            width: 100%;
            button {
              font-size: 14px !important;
              text-transform: uppercase;
              width: 90%;
            }
          }
        }
      }
    }
  }
}

//home-product-item
.home-product-item {
  margin-bottom: 30px;
  position: relative;
  @media screen and (max-width: 767px) {
    border: 1px solid #70707033;
    min-height: 305px;
  }
  .shipping-label-star {
    position: absolute;
    top: 6px;
    right: 0;
    z-index: 1;
    background-color: #e25e52;
    padding: 5px 20px;
    text-transform: uppercase;
    font-size: 14px;
    color: #fff;
    clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 10% 50%, 0% 0%);
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .sale-label-star {
    position: absolute;
    top: 0px;
    left: 6px;
    z-index: 1;
    background-color: #e25e52;
    padding: 10px 10px 30px 10px;
    text-transform: uppercase;
    font-size: 14px;
    color: #fff;
    clip-path: polygon(50% 0%, 100% 0%, 100% 75%, 50% 56%, 0% 75%, 0% 0%);
    @media screen and (max-width: 767px) {
      padding: 2px 7px;
      -webkit-clippath: none;
      clippath: none;
      font-size: 12px;
      top: 6px;
    }
  }

  .home-product-box {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    margin-bottom: 5px;
    img {
      width: 100%;
      // height: 100%;
      height: 200px;
      object-fit: contain;
      @media screen and (max-width: 767px) {
        width: 100%;
        height: 150px;
        object-fit: contain;
        object-position: center;
      }
    }
    .quickviewbtn {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        border: 1px solid #e25e52;
        color: #fff;
        background-color: #e25e52;
        font-size: 12px;
        font-weight: normal;
        text-transform: uppercase;
        padding: 7px 23px;
        margin-bottom: 0px;
      }
    }
    &:hover {
      .quickviewbtn {
        opacity: 1;
      }
    }
  }
  .home-productbox-detail {
    .shipping-mobile-label {
      display: none;
    }
    @media screen and (max-width: 767px) {
      padding: 10px;
      .shipping-mobile-label {
        background-color: #e25e52;
        color: #fff;
        font-size: 12px;
        padding: 2px 8px;
        margin: 6px 0 0;
        display: block;
        width: 110px;
      }
    }
    .home-productbox-heading {
      .price-inline-remove {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 767px) {
          min-height: auto;
          p {
            display: none !important;
          }
        }
        h2 {
          text-transform: uppercase;
          margin-bottom: 0px;
          font-size: 16px;
          font-weight: 700;
          color: #000;
          margin-bottom: 0px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-align: center;
          word-break: break-word;
          @media screen and (max-width: 767px) {
            text-align: left;
            text-transform: capitalize;
            font-weight: 400;
            font-size: 16px;
            width: 100%;
            margin-bottom: 5px;
          }
        }
        p {
          color: #e25e52;
          margin-bottom: 0;
          display: block;
          text-align: right;
          width: 100%;
        }
      }
    }
    .product-price-mobile {
      display: none;
    }
    @media screen and (max-width: 767px) {
      .product-price-mobile {
        color: #e25e52;
        font-weight: 500;
        margin-bottom: 5px;
        display: block;
        text-align: left;
        min-height: 50px;
        p {
          margin: 0;
        }
        del {
          color: #000;
          font-size: 14px;
        }
      }
    }
    .home-productbox-bottom {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .item-rating {
        display: flex;
        justify-content: space-between;
        ul {
          margin: 0px;
          padding: 0px;
          display: flex;
          align-items: center;
          li {
            list-style: none;
            margin-right: 1px;
            i {
              color: #e25e52;
              font-size: 14px;
            }
          }
        }
      }
      .home-productbox-price {
        @media screen and (max-width: 767px) {
          display: none;
        }
        p {
          font-size: 14px;
          font-weight: 700;
          color: #000;
          margin-bottom: 0px;
        }
      }
    }
  }
}
.form-field-error {
  color: red;
  font-size: 12px;
}

.editor-page {
  text-align: center;
  h1 {
    font-size: 35px;
    color: #e25e52;
  }
  h2 {
    font-size: 50px;
    text-transform: uppercase;
    margin-bottom: 30px;
    color: #e25e52;
  }
  h3 {
    font-size: 32px;
    text-transform: uppercase;
    color: #e25e52;
  }
  h4 {
    font-size: 25px;
    color: #e25e52;
  }
  h5 {
    font-size: 23px;
    color: #e25e52;
  }
  h6 {
    font-size: 35px;
    color: #e25e52;
  }
  p {
    font-size: 18px;
    color: #000;
  }
  .aboutus-top-heading {
    margin-bottom: 30px;
    h2 {
      text-align: center;
      font-size: 70px;
      @media screen and (max-width: 767px) {
        font-size: 44px;
      }
      text-transform: uppercase;
      margin: 0px;
      padding: 0px;
    }
  }
  .aboutus-section {
    text-align: center;
    margin-bottom: 30px;
    .aboutus-section-heading {
      margin-bottom: 20px;
      h3 {
        color: #e25e52;
        font-weight: 700;
        font-size: 30px;
        text-transform: uppercase;
      }
    }
    .aboutus-section-pera {
      p {
        color: #2c2c2c;
        font-size: 18px;
        &.dark-pera {
          font-weight: 500;
          color: #000;
        }
      }
    }
  }
  .aboutus-getintouch-section {
    .aboutus-getintouch-head {
      text-align: center;
      h3 {
        color: #000;
        font-weight: 700;
        font-size: 35px;
        text-transform: uppercase;
      }
      p {
        color: #000;
        font-size: 18px;
        text-transform: uppercase;
      }
    }
    .aboutus-getintouch-mid {
      ul {
        margin: 0px;
        padding: 0px;
        display: flex;
        justify-content: center;
        li {
          list-style: none;
          text-align: center;
          margin: 10px 10px;
          a {
            text-decoration: none;
            i {
              color: #e25e52;
              font-size: 40px;
              margin-bottom: 10px;
            }
            p {
              color: #2c2c2c;
              font-size: 16px;
              text-transform: uppercase;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

//custom select
.basic-multi-select {
  border-color: #d1d1d1;
  border-radius: 0px !important;
  font-size: 12px;
  color: #000;
  height: 36px;
  .css-yk16xz-control {
    border-radius: 0px !important;
    height: 36px !important;
    min-height: 0px !important;
    span {
      display: none;
    }
    svg {
      width: 16px;
      color: #000;
    }
    .css-tlfecz-indicatorContainer {
      padding: 1px;
    }
    .css-g1d714-ValueContainer {
      padding: 2px 14px;
      .css-1wa3eu0-placeholder {
        color: #000;
      }
    }
  }
  .css-1pahdxg-control {
    border-radius: 0px !important;
    height: 36px !important;
    border-color: #d1d1d1;
    min-height: 0px !important;
    &:hover {
      border-color: #d1d1d1;
    }
    span {
      display: none;
    }
    svg {
      width: 16px;
      color: #000;
    }
    .css-1gtu0rj-indicatorContainer {
      padding: 1px;
    }
    .css-g1d714-ValueContainer {
      padding: 2px 14px;
      .css-1wa3eu0-placeholder {
        color: #000;
      }
    }
  }
}

.unique-select {
  border-color: #d1d1d1;
  border-radius: 0px;
  font-size: 12px;
  color: #000;
  height: 36px;
  margin-right: 10px;
  margin-bottom: 15px;
}

//,edia
@media screen and (max-width: 991px) and (min-width: 768px) {
  //home-carousel
  .home-slider {
    .carousel-inner {
      .carousel-item {
        img {
          min-height: 350px;
        }
        .carousel-caption {
          h3 {
            font-size: 80px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  //home-carousel
  .home-slider {
    .carousel {
      .carousel-indicators {
        li {
          width: 12px;
          height: 12px;
        }
      }
    }
    .carousel-inner {
      .carousel-item {
        .carousel-caption {
          padding: 0px 10px;
          span {
            font-size: 14px;
          }
          h3 {
            font-size: 34px;
          }
          p {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 10px;
            text-transform: uppercase;
            font-weight: 400;
          }
          .banner-shopbtn {
            button {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}

.FormRow {
  margin-top: 10px;
}

.saved-text {
  margin-top: 10px;
  display: flex;
  align-items: center;
  .checkbox-round {
    width: 14px;
    height: 14px;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #777777;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }

  .checkbox-round:checked {
    background-color: #e25e52;
    border-color: #e25e52;
  }
  span {
    font-size: 14px;
    margin-left: 10px;
  }
}

.border-card-color {
  margin-top: 10px;
  color: #000;
  height: 36px;
  border: 1px solid #d1d1d1;
  padding: 10px;
}

//out of stock
.stock-out {
  p {
    margin: 0px;
    color: #e25e52 !important;
    font-size: 12px !important;
    font-weight: 700;
    width: 100px;
    margin: 0 auto;
    margin-top: 5px;
  }
}

//vendor-page sccs
.vendor-page {
  //have-account-section
  .have-account-section {
    text-align: center;
    margin-bottom: 50px;
    .have-account-logo {
      margin-bottom: 20px;
      img {
        width: 100%;
        max-width: 350px;
      }
    }
    .have-account-mid {
      h3 {
        color: #000000;
        font-weight: 500;
        font-size: 20px;
        text-transform: uppercase;
        margin-bottom: 20px;
      }
      a {
        border: 1px solid #e25e52;
        color: #fff;
        background-color: #e25e52;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 6px 20px;
        cursor: pointer;
        &:hover {
          background-color: transparent;
          color: #e25e52;
        }
      }
    }
  }

  //vendor-page-head
  .vendor-page-head {
    text-align: center;
    padding: 15px 0px;
    h2 {
      color: #e25e52;
      font-weight: 500;
      font-size: 30px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    p {
      color: #000000;
      font-weight: 400;
      font-size: 14px;
    }
  }

  //vendor-page-mid
  .vendor-page-mid {
    .vendor-page-midsection {
      .vendor-page-midsection-inner {
        padding-bottom: 20px;
        h3 {
          color: #e25e52;
          font-weight: 600;
          font-size: 18px;
          margin-bottom: 10px;
        }
        p {
          color: #000000;
          font-weight: 400;
          font-size: 14px;
          margin-bottom: 15px;
        }
        .vendor-page-inneritems {
          .vendor-page-inneritems-heading {
            margin-bottom: 20px;
            h3 {
              color: #e25e52;
              font-weight: 600;
              font-size: 18px;
              margin-bottom: 0px;
            }
          }
          .vendor-page-inneritems-detail {
            padding-bottom: 10px;
            h4 {
              color: #000;
              font-weight: 600;
              font-size: 16px;
              margin-bottom: 10px;
            }
            p {
              color: #000000;
              font-weight: 400;
              font-size: 14px;
              margin-bottom: 15px;
            }
            ul {
              margin: 0px;
              padding: 0px;
              li {
                list-style: none;
              }
            }
          }
        }
      }
    }
  }

  //vendor-page-strip
  .vendor-page-strip {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: 100vw;
      height: 100%;
      background-color: #f7f7f7;
      left: 50%;
      margin-left: -50vw;
    }
    .vendor-page-strip-inner {
      position: relative;
      z-index: 1;
      background-image: url("https://dev-subsciety-endpoint-hahygqd5brhefdb6.z01.azurefd.net/subsciety-assets/images/vendor-redy.webp");
      background-size: cover;
      padding: 100px 0px;
      h2 {
        color: #000000;
        font-weight: 500;
        font-size: 32px;
        margin-bottom: 20px;
        span {
          img {
            width: 100%;
            max-width: 170px;
          }
        }
      }
      a {
        border: 1px solid #e25e52;
        color: #fff;
        background-color: #e25e52;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 6px 20px;
        cursor: pointer;
        &:hover {
          background-color: transparent;
          color: #e25e52;
        }
      }
    }
  }
}

.custom-width {
  width: 70px;
}

.paypal-custom-btn {
  width: 30%;
  margin: 0 auto;
}

//blog-item
.blog-item {
  border: 1px solid #989898;
  margin-bottom: 20px;
  background-color: #fff;
  .blog-item-top {
    overflow: hidden;
    max-height: 170px;
    height: 100%;
    img {
      width: 100%;
      min-height: 170px;
      object-fit: cover;
    }
  }
  .blog-item-mid {
    padding: 15px;
    min-height: 120px;
    h3 {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
      a {
        color: #000;
        cursor: pointer;
        &:hover {
          color: #e25e52;
        }
      }
    }
    p {
      font-size: 13px;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 0px;
    }
    .line-clamp-para {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .blog-item-bottom {
    padding: 10px 15px 10px;
    border-top: 1px solid #d0d0d0;
    .blog-read {
      a {
        font-size: 13px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0px;
        text-transform: uppercase;
        color: #e25e52;
        cursor: pointer;
        &:hover {
          color: #000;
        }
      }
    }
    .blog-date {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      p {
        font-size: 13px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0px;
      }
    }
  }
}

.custom-calendar-suggest {
  overflow: hidden;
  .rbc-calendar {
    overflow: auto;
    .rbc-time-view {
      width: 60vw;
      @media screen and (max-width: 767px) {
        width: 1000px;
      }
    }
    .rbc-event {
      border: 1px solid #000 !important;
      .rbc-event-content {
        font-size: 10px;
      }
    }
  }
}

.represent-status {
  list-style: none;
  position: absolute;
  padding: 0;
  top: 47px;
  left: 4px;
  margin: 0;
  li {
    display: flex;
    align-items: center;
    .color-bar {
      width: 10px;
      height: 10px;
      background-color: #e25e52;
      margin-right: 4px;
    }
    .grey-color {
      background-color: grey;
    }
    .green-color {
      background-color: #00c12a;
    }
    .purple-color {
      background-color: #0423ff;
    }
    .yellow-color {
      background-color: #e4a900;
    }
    p {
      margin: 0;
      font-size: 10px;
    }
  }
}

.message-search {
  input {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid;
    font-size: 14px;
    padding: 24px 15px;
  }
}

.message-search {
  position: relative;
  input {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid;
    font-size: 14px;
    padding: 24px 15px;
    padding-right: 34px;
  }
  span {
    position: absolute;
    top: 13px;
    right: 12px;
    color: #e25e52;
    cursor: pointer;
  }
}

.page-search-not {
  text-align: center;
  margin: 20px 0;
  img {
    width: 100px;
  }
}

.rating-all-group-address {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 20px 0;
  p {
    margin: 0;
    font-size: 14px;
    text-align: left;
    &.font-timezone {
      font-size: 10px;
    }
    span {
      margin-right: 5px;
    }
  }
  .timezone-rating {
    display: flex;
    align-items: center;
    span {
      margin-right: 5px;
    }
    .timezone-clock-rating {
    }
  }
}

.image-desc-product-doba {
  p {
    img {
      width: 100%;
    }
  }
}

.a-spacing-micro {
  width: 100% !important;
}

// customization file upload button ----------------
.customization-file-upload {
  background-color: #e25e52;
  padding: 6px 20px;
  color: #fff;
  position: relative;
  width: 250px;
  text-align: center;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  input {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    height: 100%;
    opacity: 0;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}

.custom-image-upload {
  img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
  }
}

.customization-image-edit {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-height: 300px;
  overflow-y: scroll;
  position: relative;
  img {
    width: 100%;
  }
}

//customization-image-edit-inner
.customization-image-edit-inner {
  position: relative;
  .action-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    .orange-btn {
      border-radius: 100px;
      width: 40px;
      height: 40px;
      min-width: 40px;
      padding: 0px;
    }
  }
}

.image-not-upload {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  i {
    font-size: 45px;
    color: #e25e52;
  }
}

.customization-box {
  flex-flow: column;
  .custom-flex-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 0 !important;
    }
  }
  .customize-box-item-detail {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    p {
      color: #989898;
      font-size: 12px;
    }
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      object-position: center;
      margin-right: 15px;
    }
    .custom-image-preview {
      position: relative;
      margin-bottom: 15px;
      margin-left: 15px;
      i {
        color: #e25e52;
      }
      input {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
        opacity: 0;
      }
    }
  }
}

.item-custom-desc {
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.image-desc-product-doba {
  h1 {
    img {
      width: 100%;
    }
  }
}

.react-time-picker__wrapper {
  border: none !important;
}

.clock-icon {
  position: absolute;
  top: 40px;
  right: 24px;
}

.link-custom-orange {
  color: #e25e52;
  text-decoration: underline;
  &:hover {
    color: #e25e52;
    text-decoration: underline;
  }
}

//rating desc customer style ------------
.rating-desc-customer {
  list-style: none;
  padding: 0;
  margin: 20px 0;
  @media screen and (max-width: 767px) {
    h4 {
      font-size: 14px;
      font-weight: 400;
    }
  }
  li {
    text-align: left;
    border-bottom: 1px dashed #999999;
    margin-top: 15px;
    i {
      color: #e25e53;
      margin-right: 10px;
    }
    .name-date {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      @media screen and (max-width: 767px) {
        p {
          font-weight: 400;
          margin: 0;
          i {
            color: #000;
            font-size: 18px;
          }
        }
        span {
          font-size: 14px;
          color: #999999;
        }
      }
    }
  }
}

.owl-carousel {
  position: relative;
  .owl-nav {
    .owl-prev {
      position: absolute;
      top: 36%;
      left: 0;
      transform: translate(-50%, -50%);
    }
    .owl-next {
      position: absolute;
      top: 36%;
      right: -28px;
      transform: translate(-50%, -50%);
    }
    button {
      background-color: #e25e53 !important;
      color: #fff !important;
      width: 30px;
      height: 30px;
      border-radius: 100px !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
      font-size: 20px !important;
      cursor: pointer;
      span {
        display: block;
        height: 52px;
        font-size: 30px;
      }
    }
  }
}

.order-dropdown {
  button {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      display: none !important;
    }
    i {
      font-size: 20px;
    }
  }
}

.dropdown-menu a {
  cursor: pointer;
}

.note-admin-p {
  font-size: 14px;
  span {
    color: #ff0000;
    font-weight: 600;
  }
}

.one-one-metting {
  .meeting-main {
    border: 1px solid #d1d1d1;
    padding: 20px;
    border-radius: 0px 20px 20px 20px;
    width: 330px;
    box-shadow: 0px 4px 10px 0px #dedcdc;
    position: relative;
    &.right-meeting {
      margin: 20px 0 40px auto;
      border-radius: 20px 0px 20px 20px;
    }
    p {
      margin-bottom: 5px;
      strong {
        color: #000;
      }
    }
    .time-meeting {
      position: absolute;
      bottom: -30px;
      right: 12px;
      font-size: 12px;
      color: #808080;
    }
  }
}

.chating-meeting-btn {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.white-nowrap {
  white-space: nowrap;
}

.react-date-picker__wrapper {
  border: none !important;
}

.button-approved-note {
  position: relative;
  p {
    position: absolute;
    margin: 0;
    top: -26px;
    white-space: nowrap;
    left: -38px;
    font-size: 12px;
  }
}

.colorGreen {
  color: #24ad24;
}

.border-table-status {
  border: 1px solid #e25e53;
  .table {
    margin: 0;
  }
}

//seminar-info
.seminar-info {
  ul {
    margin: 0px;
    padding: 0px;
    li {
      list-style: none;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      h3 {
        font-size: 16px;
        color: #000;
        font-weight: 500;
        margin-bottom: 3px;
        min-width: 120px;
      }
      p {
        font-size: 14px;
        color: #000;
        font-weight: 400;
        margin-bottom: 0px;
      }
      a {
        font-size: 14px;
        color: #e25e52;
        font-weight: 400;
        text-decoration: underline;
        display: block;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.react-datepicker__day--selected {
  background-color: #e25e52 !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #e25e52 !important;
}

.datepicker-center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.available-slot {
  width: 100%;
  .owl-nav {
    margin-top: 0 !important;
  }
  .item {
    border: 1px solid #000;
    padding: 10px;
    white-space: normal;
    p {
      margin: 0;
      text-align: center;
    }
  }
  .owl-carousel .owl-nav .owl-prev {
    position: absolute;
    top: 31px;
    left: -28px;
  }
  .owl-carousel .owl-nav .owl-next {
    position: absolute;
    top: 31px;
    right: -58px;
  }
}

.datePicker {
  visibility: hidden;
  height: 0;
  padding: 0;
  width: 0;
  position: absolute;
}

.slots-active {
  background-color: #e25e52;
  p {
    color: #fff !important;
  }
}

.owl-stage {
  flex-wrap: nowrap !important;
  display: flex;
}

.font12 {
  font-size: 12px;
}
.font14 {
  font-size: 14px;
}

.profile-img-set {
  width: 180px;
  height: 180px;
  object-fit: contain;
  border-radius: 100px;
  margin-bottom: 15px;
}

.search-input-vendor {
  background-color: #e25e53;
  padding: 20px;
  margin-top: 0px;
  z-index: 1;
  position: relative;
  .search-icon-align {
    position: relative;
    width: 40%;
    margin: auto;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    input {
      border-radius: 0px;
      padding-right: 36px;
    }
    i {
      position: absolute;
      top: 11px;
      right: 12px;
      color: #e25e52;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    background-color: #fff;
    width: 100%;
    z-index: 1;
    margin: 0 auto;
    border: 1px solid;
    left: 0;
    right: 0;
    max-height: 300px;
    overflow: auto;
    li {
      padding: 8px 10px;
      border-bottom: 1px solid !important;
      cursor: pointer;
      &:last-child {
        border-bottom: none;
      }
      .search-filter-item {
        a {
          display: flex;
          align-items: center;
          color: #000;
          p {
            margin: 0;
          }
        }
      }
      .search-filter-item-img {
        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border: 1px solid;
          border-radius: 100px;
          margin-right: 10px;
        }
      }
    }
  }
}

.vendor-card-box {
  border: 1px solid #000000;
  text-align: center;
  padding: 20px;
  margin-bottom: 15px;
  min-height: 334px;
  @media screen and (max-width: 767px) {
    padding: 10px;
    min-height: 290px;
  }
  img {
    width: 125px;
    height: 125px;
    border: 1px solid #e25e52;
    border-radius: 100px;
    object-fit: cover;
    @media screen and (max-width: 767px) {
      width: 110px;
      height: 110px;
      margin: 0 auto;
      text-align: center;
    }
  }
  h5 {
    text-transform: uppercase;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 10px 0;
    word-break: break-word;
  }
  a {
    color: #000;
  }
}

.store-align-show {
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-category-part {
  margin: 28px 0;
  position: relative;
  img {
    width: 100%;
    height: 380px;
    object-fit: cover;
  }
  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    @media screen and (max-width: 767px) {
      width: 80%;
      h1 {
        font-size: 40px !important;
        word-break: break-word;
      }
    }
    h1 {
      margin-bottom: 0;
      font-size: 66px;
      text-transform: uppercase;
      line-height: normal;
    }
    p {
      margin: 0;
      font-size: 18px;
    }
  }
}

.home-productbox-bottom {
  p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      margin: 0;
    }
  }
}

.profile-box-custom {
  border: 2px solid #e25e53;
  width: 40%;
  margin: 30px auto;
  padding: 20px;
  h4,
  h5 {
    text-transform: uppercase;
  }
  .rating-section-inner {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .header-top-menu ul li {
    margin: 0 4px 0 3px !important;
    a,
    p {
      font-size: 12px !important;
    }
  }
}

.monthly-fees {
  strong {
    font-size: 16px;
  }
  span {
    font-weight: 600;
    font-size: 20px;
  }
}

.profile-bg {
  background-color: #fff9f8;
}
.profile-box-new {
  display: flex;
  align-items: center;
  padding: 30px 0;
  margin-bottom: 20px;
  @media screen and (max-width: 767px) {
    flex-flow: column;
  }
  img {
    width: 150px;
    min-width: 150px;
    height: 150px;
    object-fit: cover;
    margin-right: 40px;
    @media screen and (max-width: 767px) {
      margin: 0 auto;
    }
  }
  .profile-box-desc {
    width: 100%;
    @media screen and (max-width: 767px) {
      margin-top: 20px;
    }
    .subdesc-profile {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        flex-flow: column;
        h4 {
          width: 100%;
          text-align: center;
          max-width: 100% !important;
        }
      }
      h4 {
        max-width: 320px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
      }
      .rating-align {
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        .subalign-rating {
          display: flex;
          align-items: center;
          @media screen and (max-width: 767px) {
            flex-flow: column;
            .item-rating {
              margin-right: 0;
            }
            .button-align-mobile {
              display: flex;
              justify-content: center;
            }
          }
        }
        .item-rating {
          margin-right: 25px;
        }
        button {
          margin: 0 5px;
          cursor: pointer;
          &.tooltip-msg {
            position: relative;

            span {
              position: absolute;
              top: 36px;
              right: 0px;
              background-color: #fff;
              border: 1px solid #e25e53;
              border-radius: 2px;
              white-space: nowrap;
              padding: 2px 10px;
              font-size: 10px;
              text-transform: capitalize;
              color: #000;
            }
          }
        }
      }
    }
    .timezone {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 20px 0;
      font-size: 14px;
      p {
        margin-right: 10px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 20px 0;
        font-size: 14px;
        p {
          margin-right: 32px;
          margin-bottom: 0;
          display: flex;
          align-items: center;
          i {
            font-size: 20px;
            margin-right: 10px;
          }
        }
      }
    }
    .profile-para {
      font-size: 14px;
      opacity: 0.7;
      margin: 0;
    }
  }
}

.price-inline-remove {
  min-height: 70px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 767px) {
    min-height: 74px;
  }
}

.slot-header {
  background-color: #d26658 !important;
  color: #fff;
  border: none !important;
  padding: 10px 15px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .container-slot {
    margin: 0;
    display: flex;
    font-size: 14px;
  }
  .reverse-icon {
    transform: rotate(180deg);
  }
}
.reverse-icon {
  transform: rotate(180deg);
}
.slot-main-body {
  transition: height 0.35s ease;
}
.slot-body {
  padding: 0;
  .slot-desc {
    background-color: #eaeaea;
    padding: 10px;
    .slot-chip {
      background-color: #fff;
      padding: 8px;
      font-size: 12px !important;
      text-align: center;
      margin-bottom: 8px;
    }
  }
}

.menu-mobile-show {
  background-color: rgba(0, 0, 0, 0.8);
  .modal-dialog {
    .modal-content {
      background-color: transparent;
      padding: 6px;
      .modal-header {
        border: none;
        padding: 0;
        margin: 16px 0 20px;
        button {
          margin: 0;
          padding: 0;
          font-size: 35px;
          color: #fff;
          opacity: 1;
        }
      }
      .modal-body {
        padding: 0;
        .menu-button-show {
          background-color: #e25e52;
          color: #fff;
          padding: 10px 20px;
          text-align: left;
          width: 100%;
          font-weight: 400;
          font-size: 14px;
          i {
            margin-right: 10px;
            font-size: 18px;
          }
        }
        ul {
          list-style: none;
          margin: 20px 0 0;
          padding: 0;
          li {
            padding: 10px 0;
            border-bottom: 1px solid #fff;
            a {
              color: #fff;
              i {
                margin-right: 10px;
              }
            }
          }
        }
        .bottom-menu-mobile {
          margin-top: 30px;
          p {
            margin: 0;
            a {
              color: #fff;
              display: block;
              padding: 10px 0;
            }
          }
        }
      }
    }
  }
}

/* The container */
.container-slot {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container-slot input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark-slot {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: transparent;
  border-radius: 5px;
  border: 2px solid #fff;
}

/* On mouse-over, add a grey background color */
.container-slot:hover input ~ .checkmark-slot {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container-slot input:checked ~ .checkmark-slot {
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 5px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark-slot:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-slot input:checked ~ .checkmark-slot:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-slot .checkmark-slot:after {
  left: 6px;
  top: 2px;
  width: 7px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.time-slot-modal {
  background-color: #eaeaea;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    margin-bottom: 6px;
    li {
      background-color: #fff;
      padding: 8px;
      font-size: 12px;
      text-align: center;
      color: #000;
      margin: 0 10px 10px 0;
      flex-wrap: wrap;
    }
  }
}
.mobile-handburger {
  display: none;
}

@media screen and (max-width: 767px) {
  .mobile-handburger {
    display: block;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
      color: #999999;
      li {
        white-space: nowrap;
        font-size: 14px;
      }
    }
  }
}

.timezone-custom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0;
  font-size: 14px;
  @media screen and (max-width: 767px) {
    justify-content: center;
    p {
      margin-right: 10px !important;
    }
  }
  p {
    margin-right: 32px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    i {
      font-size: 20px;
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 767px) {
  .infinite-scroll-component {
    overflow: auto !important;
  }
}

.search-filter-drop {
  background-color: #f8f8f8;
  padding: 20px 10px;
  box-shadow: 0 0px 5px #ddd;
  position: absolute;
  z-index: 11;
  left: 0;
  top: 30px;
  max-height: 350px;
  overflow-y: scroll;
  @media screen and (max-width: 767px) {
    top: 47px;
  }
  .search-filter-item {
    background-color: #fff;
    padding: 5px;
    margin-bottom: 6px;
    &:nth-last-child(1) {
      margin-bottom: 0px;
    }
    a {
      display: flex;
      .search-filter-item-img {
        margin-right: 10px;
        img {
          width: 100%;
          min-width: 50px;
          width: 50px;
          height: 50px;
          object-fit: contain;
        }
        i {
          width: 100%;
          min-width: 50px;
          width: 50px;
          color: #e25e52;
          text-align: center;
          @media screen and (max-width: 767px) {
            position: relative;
            font-size: 18px;
            top: 0;
            left: 0;
            width: auto;
            min-width: auto;
          }
        }
      }
      .search-filter-item-detail {
        overflow: hidden;
        h3 {
          text-transform: uppercase;
          margin-bottom: 0px;
          font-size: 14px;
          color: #000;
          font-weight: 600;
        }
        p {
          color: #979797;
          font-weight: 500;
          font-size: 12px;
          max-width: 270px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 0px;
        }
      }
    }
    &:hover {
      background-color: #e7e7e7 !important;
    }
  }
}

.note-ecom {
  color: #fe504f;
  margin-bottom: 10px;
  display: block;
  i,
  strong {
    margin-right: 5px;
  }
}

.colorWhite {
  color: #fff;
  &:hover {
    color: #fff;
  }
}
.add-on-date {
  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 15px;
  }
}
.choose-option-custom {
  @media screen and (max-width: 767px) {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
}

@media screen and (max-width: 767px) {
  .padding-manage-show {
    .col-6 {
      padding: 0 5px;
    }
  }
  .item-rating {
    span {
      font-size: 20px;
    }
  }
  .mobile-heading-manage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      display: block !important;
    }
    p {
      margin: 0;
    }
  }
  .order-change-mobile {
    .col-lg-9 {
      order: 1;
    }
    .col-md-4 {
      order: 2;
    }
    .col-md-12 {
      order: 3;
    }
    .refine-sidebar-heading {
      h2 {
        display: none;
      }
    }
    .refine-sidebar-top {
      i {
        color: #fff;
        display: block;
      }
    }
  }
  .mobile-heading-refine {
    display: block !important;
  }
  .desktop-heading-refine {
    display: none !important;
  }
}

.mobile-heading-manage {
  span {
    display: none;
  }
}

.refine-sidebar-top {
  i {
    display: none;
  }
}

.mobile-heading-refine {
  display: none;
}

.desktop-heading-refine {
  display: block;
}
.mt30 {
  margin-top: 30px;
}
.upload-gallery {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  overflow-y: auto;
  padding: 20px 0;
  .upload-gallery-card {
    background-color: rgba(#000000, 0.05);
    border-radius: 10px;
    width: 180px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    position: relative;
    a {
      display: block;
      cursor: pointer;
    }
    video {
      width: 100%;
    }
    img {
      width: 100%;
      object-fit: contain;
    }
    .file-name {
      padding: 10px;
      span {
        color: #000;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        word-break: break-all;
      }
    }
    .close-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      i {
        color: #000;
        font-size: 22px;
      }
      &:hover {
        i {
          color: #e25e52;
        }
      }
    }
    .download-btn {
      position: absolute;
      bottom: 5px;
      right: 10px;
      cursor: pointer;
      z-index: 100;
      background: #fcecea;
      width: 30px;
      height: 28px;
      text-align: center;
      border-radius: 100%;
      i {
        color: #e25e53;
      }
    }
  }
}

//phone-input
.react-tel-input {
  &.phone-input {
    .form-control {
      width: 100%;
    }
  }
}

.item-rating {
  .MuiRating-root {
    color: #e25e52;
  }
}

//common-heading
.common-heading {
  margin-bottom: 40px;
  h2 {
    color: $dark;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 0px;
    position: relative;
    padding-bottom: 20px;
    &::after {
      content: "";
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 50px;
      height: 5px;
      background-color: $primary;
    }
  }
  @media only screen and (max-width: 767px) {
    h2 {
      font-size: 20px;
    }
  }
}

//product-card
.product-card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  cursor: pointer;
  margin-bottom: 30px;
  .card-img {
    background-color: rgba($dark, 0.03);
    position: relative;
    img {
      width: 100%;
      height: 250px;
      object-fit: contain;
    }
    .card-tags {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 1;
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      span {
        background-color: $primary;
        padding: 5px 15px;
        text-transform: uppercase;
        font-size: 12px;
        color: rgba($color-white, 1);
        border-radius: 2px;
      }
    }

    .image-status-label {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      .status-label {
        border: 1px solid rgba($primary, 1);
        color: rgba($color-white, 1);
        background-color: rgba($primary, 1);
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
        padding: 7px 23px;
        cursor: pointer;
        p {
          margin-bottom: 0px;
        }
      }
    }
  }
  .card-content {
    padding: 15px;
    min-height: 175px;
    .name {
      margin-bottom: 15px;
      h3 {
        font-size: 16px;
        font-weight: 500;
        color: $dark;
        margin-bottom: 0px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
      }
    }
    .price-with-offer {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: space-between;
      margin-bottom: 10px;
      .price {
        p {
          font-size: 14px;
          font-weight: 600;
          color: $black;
          margin-bottom: 0px;
          span {
            font-weight: 400;
            opacity: 0.5;
          }
        }
      }
      .offer-tag {
        span {
          background: rgba(210, 81, 66, 0.15);
          padding: 5px 10px;
          text-transform: uppercase;
          font-size: 12px;
          color: rgba($primary, 1);
          border-radius: 2px;
          display: inline-block;
          font-weight: 500;
          text-align: center;
        }
      }
    }

    .rating-info {
      margin-bottom: 8px;
    }
    .product-owner-info {
      display: flex;
      align-items: center;
      gap: 8px;
      .product-owner-img {
        img {
          width: 20px;
          height: 20px;
          min-width: 20px;
          object-fit: cover;
          border-radius: 100%;
        }
      }
      .product-owner-name {
        p {
          font-size: 12px;
          font-weight: 400;
          color: $dark;
          margin-bottom: 0px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          word-break: break-word;
          span {
            font-weight: 500;
          }
        }
      }
    }
  }
  &:hover {
    .card-img {
      .image-status-label {
        opacity: 1;
      }
    }
  }

  @media only screen and (max-width: 567px) {
    .card-img {
      img {
        height: 160px;
      }
      .card-tags {
        gap: 5px;
        span {
          padding: 5px 8px;
          font-size: 11px;
        }
      }

      .image-status-label {
        .status-label {
          font-size: 11px;
          padding: 5px 8px;
          cursor: pointer;
        }
      }
    }
    .card-content {
      padding: 10px;
      min-height: 160px;
      .name {
        margin-bottom: 10px;
        h3 {
          font-size: 14px;
        }
      }
      .price-with-offer {
        margin-bottom: 5px;
        flex-flow: column;
        align-items: flex-start;
        .offer-tag {
          span {
            padding: 5px 8px;
            font-size: 11px;
          }
        }
      }
      .rating-info {
        margin-bottom: 0px;
      }
      .product-owner-info {
        gap: 5px;
      }
    }
  }
}

//section-heading-inline
.section-heading-inline {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 30px;
  .see-all-link {
    padding-bottom: 15px;
    a {
      font-size: 16px;
      font-weight: 600;
      color: #c74339;
      margin-bottom: 20px;
      cursor: pointer;
      white-space: nowrap;
      &:hover {
        color: $black;
      }
    }
  }
}

//rating-info
.rating-info {
  .MuiRating-root {
    color: rgba($primary, 1);
    .MuiRating-decimal {
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 767px) {
    .MuiRating-root {
      .MuiRating-decimal {
        font-size: 16px;
      }
    }
  }
}

.custom-margin-left {
  margin-left: 32.25rem !important;
}

.theme-button {
  border: none;
  padding: 10px 20px;
  border-radius: 0px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  text-transform: none;
  &.btn-sm {
    padding: 6px 15px;
  }
  &.primary-button {
    background-color: $primary;
    color: $color-white;
    &:hover {
      background-color: darken($primary, 1);
    }
  }
  &.primary-button-outline {
    background-color: $color-white;
    color: $primary;
    border: 1px solid $primary;
    &:hover {
      background-color: darken($primary, 1);
      color: $color-white;
    }
  }
  &.radius-btn {
    border-radius: 50px;
  }
}

//page-height
.page-height {
  min-height: calc(100vh - 530px);
}

.spinner-box-sm {
  height: 425px;
  display: flex;
  align-items: center;
  justify-content: center;
}

//thankyou-box
.thankyou-box {
  margin-bottom: 20px;
  .thankyou-box-detail {
    i {
      margin-left: 45%;
      font-size: 100px;
      color: #e25d56;
      margin-bottom: 10px;
    }
    h3 {
      text-align: center;
      font-size: 22px;
      font-weight: 500;
    }
    p {
      font-size: 16px;
      margin-bottom: 8px;
    }
    .order-number {
      font-size: 16px;
      margin-bottom: 0px;
      font-weight: 600;
    }
  }
  .print-receipt {
    margin: 10px 0px;
    a {
      color: #e25d56;
      font-weight: 600;
      i {
      }
      &:hover {
        color: #333333;
      }
    }
  }
}
.item-detail-img {
  width: 100%;
  height: 350px;
  object-fit: contain;
  margin-bottom: 15px;
}
